<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Add Supplier</strong> </CCardHeader>
          <CForm autocomplete="off" @submit.prevent="submit">
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="First Name"
                    v-model="first_name"
                    placeholder="Enter first name..."
                    required
                    @keyup="check_first_name"
                    :addInputClasses="first_name_invalid ? 'is-invalid' : null"
                    :isValid="first_name ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Last Name"
                    v-model="last_name"
                    placeholder="Enter last name..."
                    required
                    @keyup="check_last_name"
                    :addInputClasses="last_name_invalid ? 'is-invalid' : null"
                    :isValid="last_name ? true : null"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CTextarea
                    label="Address"
                    v-model="address"
                    placeholder="Enter Address..."
                    required
                    @keyup="check_address"
                    :addInputClasses="address_invalid ? 'is-invalid' : null"
                    :isValid="address ? true : null"
                  />
                </CCol>
                   <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Company"
                    v-model="company"
                    placeholder="Enter company name..."
                    required
                    @keyup="check_company"
                    :addInputClasses="company_invalid ? 'is-invalid' : null"
                    :isValid="company ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Contact No"
                    v-model="contact_no"
                    placeholder="Enter contact number..."
                    required
                    @keyup="check_contact_no"
                    :addInputClasses="contact_no_invalid ? 'is-invalid' : null"
                    :isValid="contact_no ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="email"
                    autocomplete="off"
                    label="Email"
                    v-model="email"
                    placeholder="Enter Email..."
                    required
                    @keyup="check_email"
                    :addInputClasses="email_invalid ? 'is-invalid' : null"
                    :isValid="email ? true : null"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-right">
                <CButton
                  size="sm"
                  color="primary"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Submit</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SupplierCreate",

  data() {
    return {
      email: "",
      address: null,
      last_name: null,
      first_name: null,
      contact_no: null,
      company: null,
      email_invalid: false,
      last_name_invalid: false,
      first_name_invalid: false,
      contact_no_invalid: false,
      address_invalid: false,
      company_invalid: false,
    };
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState({
      isSaving: (state) => state.suppliers.isSaving,
    }),
  },
  methods: {
    check_first_name() {
      if (!this.first_name) {
        this.first_name_invalid = true;
      } else {
        this.first_name_invalid = false;
      }
    },
    check_last_name() {
      if (!this.last_name) {
        this.last_name_invalid = true;
      } else {
        this.last_name_invalid = false;
      }
    },
    check_company() {
      if (!this.company) {
        this.company_invalid = true;
      } else {
        this.company_invalid = false;
      }
    },
    check_address() {
      if (!this.address) {
        this.address_invalid = true;
      } else {
        this.address_invalid = false;
      }
    },
    check_contact_no() {
      if (!this.contact_no) {
        this.contact_no_invalid = true;
      } else {
        this.contact_no_invalid = false;
      }
    },
    check_email() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.email).toLowerCase())) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    checkForm() {
      if (
        !this.email ||
        !this.last_name ||
        !this.first_name ||
        !this.contact_no ||
        !this.address ||
        !this.company
      ) {
        this.check_last_name();
        this.check_first_name();
        this.check_company();
        this.check_contact_no();
        this.check_email();
        this.check_address();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("suppliers/saveSupplier", {
            Authorization: "bearer " + this.$store.state.access_token,
            email: this.email,
            address: this.address,
            contact_no: 0 + this.contact_no,
            last_name: this.last_name,
            first_name: this.first_name,
            company_name: this.company,
          })
          .then((response) => {
            this.clear_form();
          });
      } else {
        this.checkForm();
      }
    },
    clear_form() {
      this.contact_no = null;
      this.email = null;
      this.address = null;
      this.company = null;
      this.last_name = null;
      this.first_name = null;
    },
  },
};
</script>
 